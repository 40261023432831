
import { Fragment } from 'vue-frag'
import {CurrentView, ProjectFocusMode} from '~/store/building/-constants'
import { isMobile, isTablet } from '~/helpers/mobile/DeviceType'
import baseConstants from "~/store/base/-constants";

export default {
  name: 'OrganismProjectUI',
  components: { Fragment },
  props: {},
  data() {
    return {
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
      headerHeight: 0,
      isPhoneOrTablet: isMobile() || isTablet()
    }
  },
  computed: {
    isFloorView() {
      return this.$store.state.building.currentView === CurrentView.FLOOR
    },
    projectFocusMode() {
      return this.$store.state.building.projectFocusMode
    },
    isBuildingFocus() {
      return this.projectFocusMode === ProjectFocusMode.BUILDING
    }
  },
  watch: {},
  mounted() {
    window.addEventListener('resize', this.resizeListener)
    this.headerHeight = document.getElementById('header-project')
      ? document.getElementById('header-project').clientHeight
      : 0
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    resizeListener(e) {
      this.isMobileView = typeof window !== 'undefined' ? window.innerWidth < 767 : false
      this.windowHeight = window.innerHeight
      this.headerHeight = document.getElementById('header-project')
        ? document.getElementById('header-project').clientHeight
        : 0
      this.isPhoneOrTablet = isMobile() || isTablet()
    }
  }
}
