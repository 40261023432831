
import { ProjectFocusMode } from '~/store/building/-constants'
import MoleculeBuildingLabel from '~/components/molecules/project/MoleculeBuildingLabel.vue'
import MoleculeBuildingFloorLabel from '~/components/molecules/project/MoleculeBuildingFloorLabel.vue'
import MoleculeBuildingSelectedSpaceDetails from '~/components/molecules/project/MoleculeBuildingSelectedSpaceDetails.vue'
import baseConstants from '~/store/base/-constants'

export default {
  name: 'OrganismBuildingLabels',
  components: {
    MoleculeBuildingSelectedSpaceDetails,
    MoleculeBuildingLabel,
    MoleculeBuildingFloorLabel
  },
  props: {},
  data() {
    return {
      expandedPin: -1
    }
  },
  computed: {
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    clientManager() {
      return this.engine3d.default()
    },
    projectFocusMode() {
      return this.$store.state.building.projectFocusMode
    },
    buildings() {
      if (this.projectFocusMode !== ProjectFocusMode.DEFAULT) return []
      return this.$store.state.availability.project
    },
    floors() {
      if (![ProjectFocusMode.BUILDING, ProjectFocusMode.FLOOR].includes(this.projectFocusMode))
        return []
      return this.$store.state.availability.building
    },
    storeFilters() {
      return this.$store.state.availability.filters
    },
    filteredBuildingData() {
      return this.$store.state.availability.project.find(
        (b) => b.id === this.storeFilters.buildingId
      )
    },
    interactiveMouseEnabled() {
      return this.$store.state.base.interactiveMouseEnabled
    }
  },
  watch: {
    interactiveMouseEnabled: function (value) {
      const element = document.getElementById('project-canvas');
      if (value) {
        element?.classList.add('interactive-mouse');
        document.body.classList.add('interactive-mouse');
        this.enableMousePulse()
      } else {
        element?.classList.remove('interactive-mouse');
        document.body.classList.remove('interactive-mouse');
        this.disableMousePulse()
      }
    }
  },
  mounted() {
    this.clientManager.onUpdateLabelPosition = (state) => {
      requestAnimationFrame(() => {
        this.handleUpdateLabelPosition(state)
        this.handleBuildingMouseInMouseOutEvents(state)
        this.handleFloorMouseInMouseOutEvents(state)
      })
    }
  },
  methods: {
    handleMouseMoveForPulse(e) {
      const pulseAnimationTarget = document.getElementById('mouse-pulse-animation');
      if (!pulseAnimationTarget) return;
      pulseAnimationTarget.style.left = `${e.clientX}px`;
      pulseAnimationTarget.style.top = `${e.clientY + window.scrollY}px`;
    },
    enableMousePulse() {
      document.addEventListener('mousemove', this.handleMouseMoveForPulse);
      let pulseAnimationTarget = document.getElementById('mouse-pulse-animation');
      if (!pulseAnimationTarget) {
        pulseAnimationTarget = document.createElement('div');
        pulseAnimationTarget.id = 'mouse-pulse-animation';
        document.body.appendChild(pulseAnimationTarget);
      }
    },
    disableMousePulse() {
      document.removeEventListener('mousemove', this.handleMouseMoveForPulse);
      const pulseAnimationTarget = document.getElementById('mouse-pulse-animation');
      if (pulseAnimationTarget) {
        pulseAnimationTarget.parentElement.removeChild(pulseAnimationTarget);
      }
    },
    handleBuildingMouseInMouseOutEvents(state) {
      const events = state.filter((e) => ['in', 'out'].includes(e.eventType) && e.type === ProjectFocusMode.BUILDING)
      let interactiveMouseEnabled = false;
      for (const event of events) {
        if (event.eventType === 'in') {
          interactiveMouseEnabled = true;
        }
      }
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_INTERACTIVE_MOUSE_ENABLED), interactiveMouseEnabled)
    },
    handleFloorMouseInMouseOutEvents(state) {
      const events = state.filter((e) => ['in', 'out'].includes(e.eventType) && e.type === ProjectFocusMode.FLOOR)
      events.forEach((event) => {
        const {code, eventType} = event;
        const tempCodeSplit = code.split('_');
        const eventBuildingCode = tempCodeSplit?.[0];
        const eventFloorCode = tempCodeSplit?.[1];
        const componentRef = this.$refs.labelBuildingFloorRef?.find(l => {
          const data = l.labelData;
          return data.code === eventFloorCode && data.building.code.toLowerCase() === eventBuildingCode
        });
        if (!componentRef) return
        switch(eventType) {
          case 'in':
            componentRef.setIsHovered(true);
            break;
          case 'out':
            componentRef.setIsHovered(false);
            break;
        }
      });
    },
    handleUpdateLabelPosition(state) {
      this.updateLabelPositions(
        this.buildings,
        state.filter((e) => e.type === ProjectFocusMode.BUILDING),
        this.$refs.labelBuildingRef
      )
      this.updateLabelPositions(
        this.floors,
        state.filter(
          (e) =>
            e.type === ProjectFocusMode.FLOOR && e.code.includes(this.filteredBuildingData?.code.toLowerCase())
        ),
        this.$refs.labelBuildingFloorRef
      )
      this.updateSelectedSpaceDisplay(state.find((e) => e.type === ProjectFocusMode.SPACE))
    },
    updateSelectedSpaceDisplay(state) {
      const componentRef = this.$refs.selectedSpaceDisplay?.$el
      const { x, y } = state?.position_2d || { x: 0, y: 0 }
      if (componentRef && componentRef.style && state) {
        componentRef.style.transform = `translateX(${x}px) translateY(${y}px)`
        componentRef.style.display = `flex`
        componentRef.style.opacity = 1
      }
    },
    updateLabelPositions(labelItems = [], state = [], ref) {
      for (let i = 0; i < labelItems.length; i++) {
        const labelState = state?.find((s) => s.code.includes(labelItems[i].code.toLowerCase()))
        const componentRef = ref?.[i]?.$el
        const { x, y } = labelState?.position_2d || { x: 0, y: 0 }
        if (componentRef) {
          componentRef.style.transform = `translateX(${x}px) translateY(${y}px)`
          componentRef.style.display = `flex`
          setTimeout(() => {
            componentRef.style.opacity = 1
          }, 10)
        }
      }
    }
  }
}
